<template>
	<v-menu
		v-model="mMenu"
		:close-on-content-click="false"
		offset-y
		max-width="640px"
		min-width="300px"
	>
		<template v-slot:activator="{ on, attrs }">
			<slot name="activator" v-bind="{ on, attrs, disabled }">
				<v-btn x-small :disabled="disabled" v-bind="attrs" v-on="on">
					<v-icon small color="grey">fa fa-paint-brush</v-icon>
				</v-btn>
			</slot>
		</template>

		<v-card class="colors-editor-menu">
			<v-card-text>
				<v-tabs
					v-model="currentTabIndex"
					class="color-group-tabs"
					background-color="transparent"
					height="36px"
				>
					<v-tab v-for="item in tabs" :key="item">
						<p class="color-group-tabs__item">{{ item }}</p>
					</v-tab>
				</v-tabs>
			</v-card-text>

			<v-card-text>
				<v-tabs-items v-model="currentTabIndex">
					<v-tab-item>
						<p class="color-section-label">Background Color Options</p>
						<div class="color-section">
							<v-btn
								text link
								color="#1004DD"
								class="link-btn"
								target="_blank"
								:to="examplePageLink"
							>
								Color Best Practices and Examples
							</v-btn>
							<div v-for="group in bgColorsOptions" :key="`bg - ${group.label}`" class="color-group">
								<p class="color-group-label">{{ group.label }}</p>
								<div class="color-group-row">
									<div v-for="item in group.colors" :key="`bg - ${group.label} - ${item.label}`" class="color-item">
										<v-checkbox v-model="options.backgroundColor" :value="item.color" hide-details class="mt-0">
											<template #label>
												<div class="d-flex align-center pl-2">
													<div class="color-item-swatch" v-bind="getSwatchStyle(item)">
														<div v-if="item.noColor" class="color-item-swatch__no-color" />
													</div>
													{{ item.label }}
												</div>
											</template>
										</v-checkbox>
									</div>
								</div>
							</div>
						</div>
					</v-tab-item>

					<v-tab-item>
						<p class="color-section-label">Text Color Options</p>
						<div class="color-section">
							<v-btn
								text link
								color="#1004DD"
								class="link-btn"
								target="_blank"
								:to="examplePageLink"
							>
								Color Best Practices and Examples
							</v-btn>
							<div v-for="group in textColorsOptions" :key="`text - ${group.label}`" class="color-group">
								<p class="color-group-label">{{ group.label }}</p>
								<div class="color-group-row">
									<div v-for="item in group.colors" :key="`text - ${group.label} - ${item.label}`" class="color-item">
										<v-checkbox v-model="options.color" :value="item.color" hide-details class="mt-0">
											<template #label>
												<div class="d-flex align-center pl-2">
													<div class="color-item-swatch" v-bind="getSwatchStyle(item)" />
													{{ item.label }}
												</div>
											</template>
										</v-checkbox>
									</div>
								</div>
							</div>
						</div>
					</v-tab-item>

					<v-tab-item v-if="hasPredefinedTab">
						<p class="color-section-label">Predefined Styles</p>
						<div class="color-section">
							<div v-if="predefinedClassesOptions.length" class="color-group">
								<div class="color-group-row">
									<div v-for="style in predefinedClassesOptions" :key="`predefined - ${style}`" class="color-item">
										<v-checkbox v-model="options.class" :value="style" :label="formatPredefinedLabel(style)" multiple hide-details class="mt-0" />
									</div>
								</div>
							</div>

							<template v-if="outdatedClasses.length">
								<p class="color-section-label" :class="predefinedClassesOptions.length && outdatedClasses.length && 'mt-6'">Outdated Styles</p>
								<v-alert type="warning" class="mb-0 mt-2">
									These predefined styles are deprecated. You can only delete them. Instead, you can use the <strong>BG Colors</strong> and <strong>Text Colors</strong> options.
								</v-alert>
		
								<div class="color-group">
									<div class="color-group-row">
										<div v-for="style in outdatedClasses" :key="`outdated - ${style}`" class="color-item">
											<v-checkbox v-model="options.class" :value="style" :label="formatPredefinedLabel(style)" :disabled="checkOptionDisabled(style)" multiple hide-details class="mt-0" />
										</div>
									</div>
								</div>
							</template>
						</div>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>

			<v-card-text class="cms">
				<p class="color-section-label">Preview</p>
				<div class="color-section" :class="blockClasses({ styles: getUpdatedValue() })" :style="blockStyles({ styles: getUpdatedValue() })">
					<table>
						<tbody>
							<tr v-for="item in previewOptions" :key="item.label" class="selected-options-tr">
								<td>{{ item.label }}:</td>
								<td class="font-weight-bold pl-2">{{ item.value }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</v-card-text>

			<v-card-actions class="px-4">
				<v-spacer></v-spacer>
				<v-btn text @click="cancelEditorOptions">
					Cancel
				</v-btn>
				<v-btn text color="primary" @click="updateEditorOptions">
					Update
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import Styles from './components/Styles'

const colorOptions = [
	'backgroundColor',
	'color',
]

const defaultOptions = {
	...colorOptions.reduce((acc, option) => {
		acc[option] = undefined
		return acc
	}, {}),
	class: [],
}

export default {
    props: {
		value: { type: Array, default: () => [] },
		menu: { type: Boolean, default: undefined },
		config: { type: Object, default: undefined },
		disabled: { type: Boolean, default: false },
		formatLabel: { type: Boolean, default: false },
		formatLabelFn: { type: Function, default: undefined },
	},
    data () {
        return {
			options: { ...defaultOptions },
			examplePageLink: '/page/color-best-practices-and-examples',
			currentTabIndex: 0,
			mMenu: false,
        }
    },
    computed: {
		bgColorsOptions () {
			return this.config?.bgColors || Styles.BGColors
		},
		textColorsOptions () {
			return this.config?.textColors || Styles.TextColors
		},
		predefinedClassesOptions () {
			return this.config?.predefinedClasses || []
		},
		outdatedClassesOptions () {
			return this.config?.outdatedClasses || []
		},
		outdatedClasses () {
			const options = this.getOptions()
			if (!options.class?.length) { return [] }
			return options.class.filter((item) => this.outdatedClassesOptions.includes(item))
		},
		hasPredefinedTab () {
			return this.predefinedClassesOptions.length || this.outdatedClasses.length
		},
		previewOptions () {
			return [
				{ label: 'Background Color', value: this.getColorLabel(this.options.backgroundColor, this.bgColorsOptions) },
				{ label: 'Text Color', value: this.getColorLabel(this.options.color, this.textColorsOptions) },
				this.hasPredefinedTab && { label: 'Predefined Styles', value: this.options.class.join(', ') },
			].filter(Boolean)
		},
		tabs () {
			return [
				'BG Colors',
				'Text Colors',
				this.hasPredefinedTab && 'Predefined Styles'
			].filter(Boolean)
		},
	},
    watch: {
		menu: {
			immediate: true,
			handler (val) {
				if (typeof val === 'boolean') {
					this.mMenu = val
				}
			},
		},
		mMenu (curr) {
			this.$emit('update:menu', curr)
			if (curr) {
				this.options = this.getOptions()
			} else {
				this.currentTabIndex = 0
			}
		},
    },
    methods: {
		getSwatchStyle (item = {}) {
			const { color, border } = item
			return {
				style: {
					backgroundColor: color,
					borderColor: border,
				},
			}
		},
		getOptions () {
			const options = { ...defaultOptions }
			if (!this.value || !this.value.length) {
				return options
			}
			colorOptions.forEach((prop) => {
				const item = this.value.find((item) => item.startsWith(`${prop}::`))
				if (item) {
					options[prop] = item.split('::')[1]
				}
			})
			const classArray = this.value.filter((item) => colorOptions.every((prop) => !item.startsWith(`${prop}::`)))
			if (classArray.length) {
				options.class = classArray
			}
			return options
		},
		getUpdatedValue () {
			const value = []
			if (this.options.class?.length) {
				value.push(...this.options.class)
			}
			colorOptions.forEach((prop) => {
				if (this.options[prop]) {
					value.push(`${prop}::${this.options[prop]}`)
				}
			})
			return [...value]
		},
		cancelEditorOptions () {
			this.mMenu = false
			this.currentTabIndex = 0
		},
		updateEditorOptions () {
			this.$emit('input', this.getUpdatedValue())
			this.mMenu = false
			this.currentTabIndex = 0
		},
		checkOptionDisabled (style) {
			if (!this.options.class?.length) { return true }
			return !this.options.class.includes(style)
		},
		getColorLabel (color, colors = []) {
			if (!color) { return '' }
			let label = ''
			colors.forEach((group = {}) => {
				const { colors = [] } = group
				colors.forEach((item = {}) => {
					if (item.color === color) {
						label = item.label
					}
				})
			})
			return label
		},
		formatLabelByDefault (label) {
			if (!label) { return '' }
            const str = label.replaceAll('_', ' ').replaceAll('-', ' ')
            return this.titleCase(str)
        },
		formatPredefinedLabel (label) {
			if (!this.formatLabel) { return label }
			return this.formatLabelFn
				? this.formatLabelFn(label)
				: this.formatLabelByDefault(label)
		},
    },
}
</script>

<style scoped>
.colors-editor-menu {
	.color-group-tabs {
		flex-grow: 0;
		width: unset;
		border-radius: 0.25rem;
		background-color: #f9f9f9;

	}

	.color-group-tabs__item {
		margin-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		font-size: 1rem;
		font-weight: 600;
		text-transform: none;
		letter-spacing: normal;
	}

	::v-deep .v-tabs-bar {
		.v-tabs-slider-wrapper {
			padding: 0 0.75rem !important;
		}
	}

	.v-tab {
		position: relative;
		padding: 0 0.75rem !important;
	}

	.v-tab--active {
		background-color: transparent;
	}

	.color-section {
		padding: 0.5rem 1rem;
		border-radius: 4px;
		border: 2px solid #F1F1F1;
	}

	.color-section-label {
		margin-bottom: 0 !important;
		font-weight: 600;
	}

	.color-group {
		padding: 1rem 0;
		&:not(:last-child) {
			border-bottom: 1px solid #F1F1F1;
			padding-bottom: 1.5rem
		}
	}

	.color-group-label {
		font-size: 0.75rem;
		font-weight: 600;
		color: #9B9B9B;
	}

	.color-group-row {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem 2rem;
	}

	.color-item {
		flex: 1 1 11rem;
		max-width: 100%;
	}

	.color-item-swatch {
		flex: 0 0 auto;
		margin-right: 0.25rem;
		height: 1rem;
		width: 1rem;
		border-width: thin;
		border-style: solid;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: stretch;
		overflow: hidden;
	}

	.color-item-swatch__no-color {
		border-left: 1px solid red;
		transform: rotate(45deg);
	}

	.link-btn {
		min-height: auto;
		height: auto !important;
		padding: 0 !important;
		font-size: inherit !important;
		font-weight: 500;
		text-decoration: underline !important;
		text-transform: none;
		letter-spacing: normal;
		vertical-align: baseline;

	}

	.selected-options-tr {
		td {
			padding: 0.25rem 0;
			line-height: 1.3;
		}
	}
}
</style>
