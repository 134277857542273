<template>
    <div class="editor" @click="$emit('click', editor)">
        <editor-menu-bar v-if="!hideMenuBar" :editor="editor" v-slot="{ commands, isActive, getMarkAttrs, menu }" class="mb-3">
            <div class="menubar" v-if="show_menu || alwaysShowMenu">
                <v-btn x-small :disabled="show_code" @click="commands.bold">
                    <v-icon small :color="isActive.bold() ? 'black' : 'grey'">fas fa-bold</v-icon>
                </v-btn>
                <v-btn x-small :disabled="show_code" class="menubar__button" @click="commands.italic">
                    <v-icon small :color="isActive.italic() ? 'black' : 'grey'">fas fa-italic</v-icon>
                </v-btn>
                <v-btn x-small :disabled="show_code" @click="commands.strike">
                    <v-icon small :color="isActive.strike() ? 'black' : 'grey'">fas fa-strikethrough</v-icon>
                </v-btn>
                <v-btn x-small :disabled="show_code" @click="commands.underline">
                    <v-icon small :color="isActive.underline() ? 'black' : 'grey'">fas fa-underline</v-icon>
                </v-btn>
                <v-btn x-small :disabled="show_code" @click="showLinkMenu(getMarkAttrs('link'))">
                    <v-icon small :color="isActive.link() ? 'black' : 'grey'">fas fa-link</v-icon>
                </v-btn>
                <v-btn x-small :disabled="show_code" @click="showImageGallery(commands.image)">
                    <v-icon small :color="isActive.image() ? 'black' : 'grey'">fas fa-image</v-icon>
                </v-btn>
                <v-btn x-small :disabled="show_code" @click="showDocumentLibrary(commands.document)">
                    <v-icon small :color="isActive.image() ? 'black' : 'grey'">fas fa-file-alt</v-icon>
                </v-btn>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn x-small v-on="on" :disabled="show_code">
                            <v-icon small :color="isActive.paragraph2() ? 'black' : 'grey'">fas fa-paragraph</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <div style="display:inline-block">
                            <v-btn x-small :disabled="show_code" @click="commands.paragraph2({ classes: 'text-left' })">
                                <v-icon small
                                    :color="isActive.paragraph2({ classes: 'text-left' }) ? 'black' : 'grey'">fas
                                    fa-align-left</v-icon>
                            </v-btn><br>
                            <v-btn x-small :disabled="show_code"
                                @click="commands.paragraph2({ classes: 'text-center' })">
                                <v-icon small
                                    :color="isActive.paragraph2({ classes: 'text-center' }) ? 'black' : 'grey'">fas
                                    fa-align-center</v-icon>
                            </v-btn><br>
                            <v-btn x-small :disabled="show_code" @click="commands.paragraph2({ classes: 'text-right' })">
                                <v-icon small
                                    :color="isActive.paragraph2({ classes: 'text-right' }) ? 'black' : 'grey'">fas
                                    fa-align-right</v-icon>
                            </v-btn>
                        </div>
                    </v-card>
                </v-menu>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn x-small v-on="on" :disabled="show_code">H</v-btn>
                    </template>
                    <v-card>
                        <v-hover v-for="level in [1, 2, 3, 4, 5, 6]" :key="level" v-slot:default="{ hover }">
                            <div>
                                <v-btn x-small :disabled="show_code"
                                    @click="commands.heading({ level: level, classes: 'text-left' })">
                                    <span
                                        :class="isActive.heading({ level: 1 }) ? 'black--text' : 'grey--text'"><strong>H{{ level }}</strong></span>
                                </v-btn>
                                <div style="display:inline-block" v-if="hover">
                                    <v-btn x-small :disabled="show_code"
                                        @click="commands.heading({ level: level, classes: 'text-left' })">
                                        <v-icon small
                                            :color="isActive.heading({ level: level, classes: 'text-left' }) ? 'black' : 'grey'">fas
                                            fa-align-left</v-icon>
                                    </v-btn>
                                    <v-btn x-small :disabled="show_code"
                                        @click="commands.heading({ level: level, classes: 'text-center' })">
                                        <v-icon small
                                            :color="isActive.heading({ level: level, classes: 'text-center' }) ? 'black' : 'grey'">fas
                                            fa-align-center</v-icon>
                                    </v-btn>
                                    <v-btn x-small :disabled="show_code"
                                        @click="commands.heading({ level: level, classes: 'text-right' })">
                                        <v-icon small
                                            :color="isActive.heading({ level: level, classes: 'text-right' }) ? 'black' : 'grey'">fas
                                            fa-align-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-hover>
                    </v-card>
                </v-menu>
                <v-btn x-small :disabled="show_code" @click="commands.bullet_list">
                    <v-icon small :color="isActive.bullet_list() ? 'black' : 'grey'">fas fa-list-ul</v-icon>
                </v-btn>
                <v-btn x-small :disabled="show_code" @click="commands.ordered_list">
                    <v-icon small :color="isActive.ordered_list() ? 'black' : 'grey'">fas fa-list-ol</v-icon>
                </v-btn>
                <v-btn x-small :disabled="show_code" @click="commands.horizontal_rule">
                    _
                </v-btn>
				<ColorsEditorMenu
					v-model="selected_styles"
					:menu.sync="class_menu"
					:config="colorsEditorConfig"
					:disabled="show_code"
					format-label
				/>
                <v-btn x-small @click="toggleCode" v-if="hasRole(['SuperAdmin'])">
                    <v-icon :class="show_code ? 'black--text' : 'grey--text'" small>fas fa-code</v-icon>
                </v-btn>
                <v-dialog v-model="linkMenuIsActive" width="500">
                    <v-card>
                        <v-card-text>
                            <form class="menububble__form" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                                <v-text-field v-model="linkUrl" placeholder="https://" ref="linkInput"
                                    @keydown.esc="hideLinkMenu" />
                                <v-checkbox class="mt-0" v-model="linkTarget" label="Open in new tab"
                                    false-value="_self" true-value="_blank"></v-checkbox>
                                <v-radio-group :column="true" v-model="linkFormat">
                                    <v-radio label="Link" value="link"></v-radio>
                                    <v-radio label="Button 1" value="button-1" class="mr-4"></v-radio>
                                    <v-radio label="Button 2" value="button-2" class="mr-4"></v-radio>
                                    <v-radio label="Button 3" value="button-3" class="mr-4"></v-radio>
                                    <v-radio label="Inverted" value="button-invert" class="mr-4"></v-radio>
                                </v-radio-group>
                                <button class="menububble__button" @click="setLinkUrl(commands.link, null)"
                                    type="button">
                                    <icon name="remove" />
                                </button>
                            </form>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="setLinkUrl(commands.link, linkUrl)">
                                Save
                            </v-btn>
                            <v-btn text @click="setLinkUrl(commands.link, null)">
                                Remove
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-tooltip top>
							<template v-slot:activator="tooltip">
								<v-btn x-small :disabled="show_code" class="menubar__button" v-bind="{ ...tooltip.attrs, ...attrs }" v-on="{ ...tooltip.on, ...on }">
									{...} <v-icon right>fa-solid fa-caret-down</v-icon>
								</v-btn>
							</template>
							Merge Fields
						</v-tooltip>
					</template>
					<v-list dense>
						<v-list-item v-for="field in mergeFields" :key="field" class="d-flex justify-space-between" @click="insertText(field)">
							<span>{{ field }}</span>
							<v-icon @click.stop="copy(field)" right>fas fa-copy</v-icon>
						</v-list-item>
					</v-list>
				</v-menu>
            </div>
        </editor-menu-bar>
        <!--
        <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
            <div class="menububble" :class="{ 'is-active': menu.isActive }" :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`">
                <template v-else>
                    <button class="menububble__button" @click="showLinkMenu(getMarkAttrs('link'))" :class="{ 'is-active': isActive.link() }">
                        <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                        <icon name="link" />
                    </button>
                </template>
            </div>
        </editor-menu-bubble>
        //-->
        <editor-content v-if="!show_code" class="editor__content" :editor="editor" />
        <textarea style="width:100%; min-height:400px" v-else solo v-model="html_code"></textarea>
        <v-dialog v-model="show_media" width="75%">
            <media-library v-on:usefile="useFile"></media-library>
        </v-dialog>
        <v-dialog v-model="show_documents" width="75%">
            <document-library :is-popup="true" v-on:usefile="useDocumentFile"></document-library>
        </v-dialog>
        <v-snackbar v-model="copiedSnackbar" color="success">
            Copied!
        </v-snackbar>
    </div>
</template>
<script>
import Icon from './Icon.vue'
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import { prettyPrint } from 'html'
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Strike,
    Underline,
    History,
    Image,
} from 'tiptap-extensions'
//import Align from './tip_tap_align.js';
import CustomStyle from './tip_tap_style.js';
import Link from './tip_tap_link.js';
import Div from './tip_tap_div.js';
import Heading from './tip_tap_heading.js';
import Paragraph2 from './tip_tap_paragraph.js';
import MediaLibrary from '../mediaLibrary.vue'
import Document from './tip_tap_document.js';
import DocumentLibrary from '../documentLibrary.vue'
import ColorsEditorMenu from '../ColorsEditorMenu.vue'
import Styles from './Styles'

const fields = [
	'{{AgentCode}}',
	'{{AgentName}}',
    '{{AgentID}}',
    '{{AgentEmail}}',
	'{{AgentPhone}}',
	'{{UplineAgentName}}',
	'{{NextRankedUplineAgentName}}',
	'{{NextRankedUplineAgentPreferredName}}',
	'{{BaseShopOwnerName}}',
	'{{SFGDirectName}}',
]

export default {
    components: {
        EditorContent,
        EditorMenuBar,
        Icon,
        EditorMenuBubble,
        MediaLibrary,
        DocumentLibrary,
		ColorsEditorMenu,
    },
    props: {
		value: {
			type: String,
			default: '',
		},
		alwaysShowMenu: {
			type: Boolean,
			default: false,
		},
		hideMenuBar: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
            innerValue: '',
            class_menu: false,
            linkFormat: 'link',
            html_code: null,
            show_code: false,
            linkUrl: null,
            linkTarget: '_self',
            linkMenuIsActive: false,
            show_menu: false,
            media_command: function () { },
            document_command: function () { },
            show_media: false,
            show_documents: false,
            selected_styles: [],
            copiedSnackbar: false,
            mergeFields: fields,
            editor: new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Document(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                    new Paragraph2(),
                    //new Align(),
                    new Image(),
                    new CustomStyle(),
                    new Div(),
                ],
                content: ``,
                onUpdate: ({ getHTML }) => {
                    const html = getHTML();
                    this.innerValue = html;
                    this.$emit('input', html);
                },
                onFocus: () => {
                    this.show_menu = true;
                    this.$emit('hasfocus');
                },
                onDrop(view, event, slice, moved) {
                    // return true to stop the drop event
                    // this will just prevent drop from external sources
                    return !moved;
                },
            }),
			colorsEditorConfig: {
				outdatedClasses: Styles.WysiwygStyles,
			},
        }
    },
    mounted: function () {
        this.editor.setContent(this.value);
        this.innerValue = this.value;
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    methods: {
		insertText (text) {
			const transaction = this.editor.state.tr.insertText(text)
			this.editor.view.dispatch(transaction)
			this.editor.focus()
		},
        copy (text) {
            navigator.clipboard.writeText(text)
				.then(() => {
					this.copiedSnackbar = true;
				})
        },
        toggleCode: function () {
            this.show_code = !this.show_code;
            if (this.show_code) {
                this.html_code = prettyPrint(this.editor.getHTML());
            } else {
                this.$emit('input', this.html_code);
                this.editor.setContent(this.html_code);
            }
        },
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
        hideLinkMenu() {
            this.linkTarget = "_self";
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
        setLinkUrl(command, url) {
            if (typeof url == 'undefined' || url == null) {
                this.hideLinkMenu()
                return
            }
            if (url.indexOf('quility.com') > 0) {
                command({ href: url, class: this.linkFormat, target: this.linkTarget })
            } else {
                command({ href: url, class: this.linkFormat, target: "_blank" })
            }
            this.hideLinkMenu()
        },
        showImageGallery(command) {
            this.show_media = true;
            this.media_command = command;
        },
        showDocumentLibrary(command) {
            this.show_documents = true;
            this.document_command = command;
        },
        useFile(src) {
            if (src !== null) {
                this.media_command({ src });
            }
            this.show_media = false;
        },
        useDocumentFile(destination) {
            this.document_command({ href: destination.url, class: destination.style, target: "_blank" })
            this.show_documents = false;
        },
        setMediaCommand(command) {
            this.media_command = command;
        },
        updateClasses: function (command) {
            command({ classes: this.selected_styles })
        },
        getHtml: function () {
            return this.editor.getHTML();
        },
        setHtml: function (v) {
            this.editor.setContent(v);
        },
		getClasses (items = []) {
			if (!items || !items.length) { return [] }
			return items.filter(((item) => !item.includes('::')))
		},
		getStyles (items = []) {
			if (!items || !items.length) { return {} }
			const styles = items.filter((item) => item.includes('::'))
			if (styles.length) {
				return styles.reduce((acc, style) => {
					const [prop, value] = style.split('::')
					acc[prop] = value
					return acc
				}, {})
			}
			return {}
		},
		getHexFromRgb (colorStr) {
			if (!colorStr?.startsWith('rgb(')) { return colorStr }
			const rgb = colorStr.split('(')[1].split(')')[0].split(',')
			const hex = rgb.map((x) => {
				const part = parseInt(x).toString(16).toUpperCase()
				return part.length === 2 ? part : `0${part}`
			}).join('')
			return `#${hex}`
		},
    },
    watch: {
        class_menu (newV) {
            if (newV) {
				const { classes, backgroundColor, color } = this.editor.activeMarkAttrs.customstyle
				const classesArr = classes
					? typeof classes === 'string'
						? classes.split(',').map((item) => item.replace('custom-style', '').trim())
						: classes
					: []
				this.selected_styles = [
					...classesArr,
					backgroundColor && `backgroundColor::${this.getHexFromRgb(backgroundColor)}`,
					color && `color::${this.getHexFromRgb(color)}`,
				].filter(Boolean)
            } else {
				const classes = this.getClasses(this.selected_styles)
				const styles = this.getStyles(this.selected_styles)
				this.editor.commands.customstyle({ classes, ...styles })
			}
        },
        value(newValue) {
            if (newValue === this.innerValue) { return }
            this.editor.setContent(this.value);
        }
    }
}

</script>
<style>
.editor {
    width: 100%;
}

.editor__content input:focus {
    outline: none;
}

[contenteditable]:focus {
    outline: 0px solid transparent;
}

.editor__content {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

}
</style>
