import { Mark } from "tiptap";
import { updateMark, removeMark } from "tiptap-commands";

export default class CustomStyle extends Mark {
    get name() {
        return "customstyle";
    }

    get schema() {
        return {
            attrs: {
                classes: {
                    default: []
                },
                backgroundColor: {
                    default: ''
                },
                color: {
                    default: ''
                },
            },
            parseDOM: [{
				tag: 'span',
				getAttrs: (dom) => ({
					classes: [...dom.classList],
					backgroundColor: dom.style.getPropertyValue('background-color'),
					color: dom.style.getPropertyValue('color'),
				})
			}],
            toDOM: (mark) => {
                const { classes, backgroundColor, color } = mark.attrs
                // if (typeof classes.contains === 'function' && !classes.contains('custom-style')) {
                //     classes.add('custom-style')
                // }
				const styles = [
					backgroundColor && `background-color:${backgroundColor};`,
					color && `color:${color};`,
				].filter(Boolean).join('')
				const attrs = {
					...(classes?.length && { class: `${classes.join(' ')}` }),
					...(styles && { style: styles }),
				}
                return ['span', attrs, 0]
            }
        };
    }

	commands ({ type }) {
        return (attrs) => {
            if (attrs.backgroundColor || attrs.color || attrs.classes?.length) {
                return updateMark(type, attrs)
            }
            return removeMark(type)
        }
    }
}
