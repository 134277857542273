export default {
    Backgrounds: [
        "white",
        "black",
        "q_symm_blue",
        "indigo",
        "water-green",
        "grass-green"
    ],
    Text: [
        "light-on-dark",
        "dark-on-light"
    ],
    BlockStyles: [
        "quility_green",
        "symmetry_blue",
        "q-card",
        "elevation-4",
        "light-on-dark",
        "grey-block",
        "white-block",
        "grass-green-block",
        "water-green-block",
        "blue-block",
        "sfg_sky_blue-block",
        "sfg_grey-block",
        "sfg_light_grey-block",
        "sfg_neon_green-block",
        "sfg_yellow-block",
        "sfg_midnight_blue-block",
        "sfg_royal_blue-block",
        "sfg_medium_green-block",
    ],
    CTAStyles: [
        "cta-green",
        "cta-blue"
    ],
    WysiwygStyles: [
        "forest-green-text",
        "water-green-text",
        "grass-green-text",
        "royal-blue-text",
        "quote",
        "border-l-white",
        "border-l-gray",
        "with-short-rule",
        "first-with-short-rule"
    ],
    PageStyles: [
        "pure-white",
        "hamsters"
    ],
	BGColors: [
		{
			label: 'Neutral',
			colors: [
				{ label: 'White', color: '#FFFFFF', border: '#000000' },
				{ label: 'Black', color: '#000000', border: '#000000' },
				{ label: 'Light Grey', color: '#F1F1F1', border: '#F1F1F1' },
				{ label: 'Dark Grey', color: '#8D8D8D', border: '#8D8D8D' },
				{ label: 'No Color', color: 'transparent', border: '#000000', noColor: true },
			],
		},
		{
			label: 'Quility',
			colors: [
				{ label: 'Forest Green', color: '#005851', border: '#005851' },
				{ label: 'Water Green', color: '#46C3B2', border: '#46C3B2' },
			],
		},
		{
			label: 'Symmetry Financial Group',
			colors: [
				{ label: 'Sky Blue', color: '#00B7FF', border: '#00B7FF' },
				{ label: 'Midnight Blue', color: '#011684', border: '#011684' },
				{ label: 'Royal Blue', color: '#1004DD', border: '#1004DD' },
			],
		},
	],
	TextColors: [
		{
			label: 'Neutral',
			colors: [
				{ label: 'White', color: '#FFFFFF', border: '#000000' },
				{ label: 'Black', color: '#000000', border: '#000000' },
				{ label: 'Light Grey', color: '#F1F1F1', border: '#F1F1F1' },
				{ label: 'Dark Grey', color: '#8D8D8D', border: '#8D8D8D' },
			],
		},
		{
			label: 'Quility',
			colors: [
				{ label: 'Forest Green', color: '#005851', border: '#005851' },
				{ label: 'Water Green', color: '#46C3B2', border: '#46C3B2' },
			],
		},
		{
			label: 'Symmetry Financial Group',
			colors: [
				{ label: 'Sky Blue', color: '#00B7FF', border: '#00B7FF' },
				{ label: 'Midnight Blue', color: '#011684', border: '#011684' },
				{ label: 'Royal Blue', color: '#1004DD', border: '#1004DD' },
			],
		},
	],
}
