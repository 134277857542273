import Agent from '@/store/Models/Agent'
import AgentProfile from '@/store/Models/AgentProfile'
import { getInstance } from "@/auth/index";
export default {

    getAgents(filters, options, canViewAllAgents) {
        this.cancelQRequest("QGetAgents")
        /*if (typeof this.current_request.abort != 'undefined') {
            this.current_request.abort();
        }*/
        Agent.commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }

        let endpoint = '/api/my/agents';
        if (canViewAllAgents === true) {
            endpoint = '/api/private/agents'
        }

        return this.getRequest(endpoint, params, "QGetAgents").then(function(json) {
            if (params.itemsPerPage != 'all') {
                Agent.create({ data: json.data })
            }
            Agent.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
            return json
        })
    },

    getAgentProfiles(Division) {
        AgentProfile.commit((state) => {
            state.loading = true
        })
        return this.getRequest('/api/private/agentprofiles', { Division: Division }).then(function(json) {
            AgentProfile.insert({ data: json.data })
            AgentProfile.commit((state) => {
                state.loading = false
            })
            return json
        })
    },

    getAgentLicensing(agent, role) {
        var url = null;
        if (role == "AgencyOwner" || role == "SalesRep") {
            url = '/api/my/agents/' + agent.AgentCode + '/licensing/producer/surelc';
        } else {
            //everyone else
            url = '/api/private/agents/' + agent.AgentCode + '/licensing/producer/surelc';
        }
        return this.getRequest(url).then(function(json) {
                return json
            },
            function(error) {
                return error
            });
    },

    getAgentStateLicensingStore(agent, role) {
        var url = null;
        if (role == "AgencyOwner" || role == "SalesRep") {
            url = '/api/my/agents/' + agent.AgentCode + '/statelicense_store';
        } else {
            //everyone else
            url = '/api/private/agents/' + agent.AgentCode + '/statelicense_store';
        }
        return this.getRequest(url).then(function(json) {
                return json
            },
            function(error) {
                return error
            });
    },

    /**used to pull historically archived data from agentsync.  */
    getAgentAgentSyncLicensing(agent, role) {
        var url = null;
        if (role == "AgencyOwner" || role == "SalesRep") {
            url = '/api/my/agents/' + agent.AgentCode + '/licensing/producer/agentsync';
        } else {
            //everyone else
            url = '/api/private/agents/' + agent.AgentCode + '/licensing/producer/agentsync';
        }
        return this.getRequest(url).then(function(json) {
                return json
            },
            function(error) {
                return error
            });
    },

    createAgentProfile(agent) {
        return this.postRequest('/api/private/agentprofiles', agent).then(
            function(json) {
                AgentProfile.insert({ data: json.data })
                return json.data
            },
            function(error) {
                return error
            })
    },

    saveAgentProfile(agent) {
        return this.putRequest('/api/private/agentprofiles/' + agent.id, agent).then(
            function(json) {
                AgentProfile.insert({ data: json.data })
                return json.data
            },
            function(error) {
                return error
            })
    },

    deleteAgentProfile(agent) {
        return this.deleteRequest('/api/private/agentprofiles/' + agent.id, agent).then(
            function(json) {
                AgentProfile.delete(agent.id)
                return json
            },
            function(error) {
                return error
            })
    },

    getAgent(agentcode, attributes) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return;
        }
        this.cancelQRequest("QAgentDetails")
        Agent.commit((state) => {
            state.loading = true
            state.currentAgent = null
        })
        var params = {};
        if (attributes) {
            var params = {
                attr: attributes.join(",")
            }
        }
        return this.getRequest('/api/private/agents/' + agentcode, params).then(function(json) {
            Agent.commit((state) => {
                state.loading = false
                state.currentAgent = json.data
            })
            return json.data;
        })
    },

    /**
     * Same as above but doesn't set the store/state for the current agent... just returns the data.
     * @param  {[type]} agentcode  [description]
     * @param  {[type]} attributes [description]
     * @return {[type]}            [description]
     */
    getAgentStateless(agentcode, attributes) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        this.cancelQRequest("QAgentDetails")
        Agent.commit((state) => {
            state.loading = true
        })
        var params = {};
        if (attributes) {
            var params = {
                attr: attributes.join(",")
            }
        }
        return this.getRequest('/api/private/agents/' + agentcode, params, "QAgentDetails").then(function(json) {
            Agent.commit((state) => {
                state.loading = false
            })
            return json.data;
        })
    },

    getMyAgent(agentcode, attributes) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return;
        }
        this.cancelQRequest("QAgentDetails")
        Agent.commit((state) => {
            state.loading = true
            state.currentAgent = null
        })
        var params = {};
        if (attributes) {
            var params = {
                attr: attributes.join(",")
            }
        }
        return this.getRequest('/api/my/agents/' + agentcode, params).then(function(json) {
            Agent.commit((state) => {
                state.loading = false
                state.currentAgent = json.data
            })
            return json.data;
        })
    },

    getAgentDownline(agentcode, attributes, type, isAgencyOwner, dontCancelRequests) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        if (dontCancelRequests === true) {

        } else {
            this.cancelQRequest("agentDownlineQ")
        }
        Agent.commit((state) => {
            state.loading = true
        })
        var params = {};
        if (attributes) {
            var params = {
                attr: attributes.join(",")
            }
        }
        if (isAgencyOwner) {
            return this.getRequest('/api/my/agents/' + agentcode + "/" + type, params, "agentDownlineQ").then(function(json) {
                Agent.commit((state) => {
                    state.loading = false
                })
                return json;
            })
        } else if (agentcode == null) {
            return this.getRequest('/api/private/agents/', { ...params, itemsPerPage: 'all' }, "agentDownlineQ").then(function(json) {
                Agent.commit((state) => {
                    state.loading = false
                })
                return json;
            })
        } else {
            return this.getRequest('/api/private/agents/' + agentcode + "/" + type, params, "agentDownlineQ").then(function(json) {
                Agent.commit((state) => {
                    state.loading = false
                })
                return json;
            })
        }
    },

    getAgentDownlineDirectory(agentcode, filters, isAgencyOwner) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        this.cancelQRequest("agentDownlineQ")
        Agent.commit((state) => {
            state.loading = true
        })
        var params = { ...filters };
        if (isAgencyOwner) {
            return this.getRequest('/api/my/agents/' + agentcode + "/mastershop", params, "agentDownlineDirectoryQ").then(function(json) {
                Agent.commit((state) => {
                    state.loading = false
                })
                return json;
            })
        } else {
            return this.getRequest('/api/private/agents/' + agentcode + "/mastershop", params, "agentDownlineDirectoryQ").then(function(json) {
                Agent.commit((state) => {
                    state.loading = false
                })
                return json;
            })
        }
    },

    getAgentUpline(agentcode, attributes, isAgent) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {};
        if (attributes) {
            var params = {
                attr: attributes.join(",")
            }
        }
        if (isAgent) {
            return this.getRequest('/api/my/agents/' + agentcode + "/upline", params, "agentUplineQ").then(function(json) {
                return json;
            })
        } else {
            return this.getRequest('/api/private/agents/' + agentcode + "/upline", params, "agentUplineQ").then(function(json) {
                return json;
            })
        }
    },

    getSymmetryAgentSearch(options) {
        this.cancelQRequest("symmetryAgentSearchQ")
        var params = {
            ...options
        }
        
        Agent.commit((state) => {
            state.loading = true
        })

        return this.getRequest('/api/my/symmetry/agents', params, "symmetryAgentSearchQ").then(function(json) {
            Agent.commit((state) => {
                state.loading = false
            })
            return json;
        })
    },

    getAgentDownlineSearch(agentcode, options, type, isAgencyOwner, callback) {
        this.cancelQRequest("agentDownlineQ")
        var params = {
            ...options
        }
        Agent.commit((state) => {
            state.loading = true
        })
        if (isAgencyOwner) {
            if (type == "agents" || type == null) {
                type = 'mastershop'
            }
            return this.getRequest('/api/my/' + type, params, "agentDownlineQ").then(function(json) {
                Agent.commit((state) => {
                    state.loading = false
                })
                callback(json);
            })
        } else if (agentcode == null) {
            //params.LeadershipLevel = "isAgencyOwner";
            return this.getRequest('/api/private/agents', params, "agentDownlineQ").then(function(json) {
                Agent.commit((state) => {
                    state.loading = false
                })
                callback(json);
            })
        } else {
            return this.getRequest('/api/private/agents/' + agentcode + "/" + type, params, "agentDownlineQ").then(function(json) {
                Agent.commit((state) => {
                    state.loading = false
                })
                callback(json);
            })
        }
    },

    /**
     * Original full month agent stats end point
     * You can now pass a start date and end date through the options
     * @param  {[type]} agentcode [description]
     * @param  {[type]} options   [description]
     * @param  {[type]} role      [description]
     * @return {[type]}           [description]
     */
    getAgentStats(agentcode, options, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var url = "";
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff' || role == 'ContentEditor') {
            url = '/api/private/agents/' + agentcode + "/stats"
        } else if (role == 'AgencyOwner') {
            url = '/api/my/agents/' + agentcode + "/stats"
        } else {
            url = '/api/my/stats'
        }
        this.cancelQRequest("agentStatsQ" + options.type)
        return this.getRequest(url, options, 'agentStatsQ').then(function(json) {
            return (json);
        })

    },

    /**
     * Uses the new month to date stats pulled from applications.
     * @param  {[type]} agentcode [description]
     * @param  {[type]} options   [description]
     * @param  {[type]} role      [description]
     * @return {[type]}           [description]
     */
    getAgentStatsCurrentMonth(agentcode, options, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var url = "";
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff' || role == 'ContentEditor') {
            url = '/api/private/agents/' + agentcode + "/application_stats/mtd"
        } else if (role == 'AgencyOwner') {
            url = '/api/my/agents/' + agentcode + "/application_stats/mtd"
        } else {
            url = '/api/my/application_stats/mtd'
        }
        this.cancelQRequest("agentStatsQ" + options.type)
        return this.getRequest(url, options, 'agentStatsQ').then(function(json) {
            return (json);
        })
    },

    /**
     * Uses the new week to date stats pulled from applications.
     * @param  {[type]} agentcode [description]
     * @param  {[type]} options   [description]
     * @param  {[type]} role      [description]
     * @return {[type]}           [description]
     */
    getAgentStatsCurrentWeek(agentcode, options, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var url = "";
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff' || role == 'ContentEditor') {
            url = '/api/private/agents/' + agentcode + "/application_stats/wtd"
        } else if (role == 'AgencyOwner') {
            url = '/api/my/agents/' + agentcode + "/application_stats/wtd"
        } else {
            url = '/api/my/application_stats/wtd'
        }
        this.cancelQRequest("agentStatsQ" + options.type)
        return this.getRequest(url, options, 'agentStatsQ').then(function(json) {
            return (json);
        })
    },

    getNewAgentStats(agentcode, options, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var url = "";
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff' || role == 'ContentEditor') {
            url = '/api/private/agents/' + agentcode + "/newagent_stats"
        } else if (role == 'AgencyOwner') {
            url = '/api/my/agents/' + agentcode + "/newagent_stats"
        } else {
            url = '/api/my/newagent_stats'
        }
        this.cancelQRequest("agentStatsQ" + options.type)
        return this.getRequest(url, options, 'agentStatsQ').then(function(json) {
            return (json);
        })
    },

    getNewAgents(agentcode, options, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var url = "";
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff' || role == 'ContentEditor') {
            url = '/api/private/agents/' + agentcode + "/newagents"
        } else if (role == 'AgencyOwner') {
            url = '/api/my/agents/' + agentcode + "/newagents"
        } else {
            url = '/api/my/newagents'
        }
        this.cancelQRequest("agentStatsQ" + options.type)
        return this.getRequest(url, options, 'agentStatsQ').then(function(json) {
            return (json);
        })
    },

    getAgentDocumentList(agentcode, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var url = null
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff' || role == 'ContentEditor') {
            url = '/api/private/agents/' + agentcode + "/documents";
        } else if (role == 'AgencyOwner') {
            url = '/api/my/agents/' + agentcode + "/documents";
        } else {
            url = '/api/my/documents';
        }
        this.cancelQRequest("agentDocumentsQ")
        return this.getRequest(url, {}, 'agentDocumentsQ').then(function(json) {
            return (json);
        })
    },

    getAgentComplianceDocuments(agentcode) {
        this.cancelQRequest("QComplianceCorner");
        return this.getRequest(`/api/my/agents/${agentcode}/compliance_documents`).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getDocument(id) {
        if (typeof id == 'undefined' || id == null || id == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var url = '/api/private/cms/documents/' + id;
        this.cancelQRequest("agentDocumentsQ")
        return this.getRequest(url, {}, 'agentDocumentsQ').then(function(json) {
            return (json);
        })
    },

	async getLeadsheets (agentCode, leadCode) {
		const nullish = [undefined, null, ' ', '-', '_', '']
        if (nullish.includes(agentCode) || nullish.includes(leadCode)) {
            return Promise.resolve({
				error: true,
				msg: 'LeadCode or AgentCode not defined',
			})
        }

        const url = `/api/private/agents/${agentCode}/leadmedia/${leadCode}`
        const token = await this.config()

        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then((response) => {
			if (!response.ok) {
				throw new Error(response.statusText)
			}
			const reader = response.body.getReader()
			return new ReadableStream({
				start (controller) {
					function pump () {
						return reader.read().then(({ done, value }) => {
							if (done) {
								controller.close()
								return
							}
							controller.enqueue(value)
							return pump()
						})
					}
					return pump()
				},
			})
		})
		.then((stream) => new Response(stream))
		.then((response) => response.blob())
		.then((blob) => URL.createObjectURL(blob))
		.then((url) => url)
		.catch((err) => ({ error: true, msg: err.message }))
	},

    deleteAgentDocument(id) {
        return this.deleteRequest('/api/private/agent_document/' + id)
			.catch((err) => {
				const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
				return { error: err, message }
			})
    },

    updateAgentDocument(doc) {
        var d = {
            DocTitle: doc.DocTitle,
            DocDesc: doc.DocDesc,
            ExpDate: doc.ExpDate,
            DocumentTags: doc.DocumentTags,
        }
        return this.putRequest('/api/private/agent_document/' + doc.ID, d).then(
            function(json) {
                return json
            },
            function(err) {
                if (err.status == 404) {
                    err.msg = "Image not found"
                } else {
                    err = err
                }
                return (err)
            })
    },


    getAgentNoteList(agentcode, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        this.cancelQRequest("agentNotesQ")
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff') {
            return this.getRequest('/api/private/agents/' + agentcode + "/notes", {}, 'agentNotesQ').then(function(json) {
                return (json);
            })

        } else if (role == 'AgencyOwner') {
            return this.getRequest('/api/my/agents/' + agentcode + "/notes", {}, 'agentNotesQ').then(function(json) {
                return (json);
            })
        } else {
            return this.getRequest('/api/my/notes', {}).then(function(json) {
                return (json);
            })
        }
    },

    getAgentStickyNoteList(agentcode, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        this.cancelQRequest("agentStickyNotesQ")
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff') {
            return this.getRequest('/api/private/agents/' + agentcode + "/stickynotes", {}, 'agentStickyNotesQ').then(function(json) {
                return (json);
            })
        }
        return new Promise((resolve, reject) => { reject() });
    },

    deleteAgentNote(id) {
        return this.deleteRequest('/api/private/agent_note/' + id)
			.catch((err) => {
				const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
				return { error: err, message }
			})
    },

    updateAgentNote(note) {
        var d = {
            NoteTitle: note.NoteTitle,
            NoteText: note.NoteText,
            Link: note.Link,
            StickyInd: note.StickyInd
        }
        return this.putRequest('/api/private/agent_note/' + note.ID, d).then(
            function(json) {
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Note not found"
                } else {
                    msg = err.response.message
                }
                return (msg)
            })
    },

    createAgentNote(agent, note) {
        var n = {
            AgentCode: agent.AgentCode,
            NoteTitle: note.NoteTitle,
            NoteText: note.NoteText,
            Link: note.Link,
            StickyInd: note.StickyInd
        }
        return this.postRequest('/api/private/agent_note', n).then(
            function(json) {
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Could not create note."
                } else {
                    msg = err.message
                }
                return (err)
            })
    },

    getAgentSupportTickets(agent, filters, options, role) {
        var params = {
            ...options,
            ...filters
        }
        var url = null
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff' || role == 'ContentEditor') {
            url = '/api/private/agents/' + agent.AgentCode + '/tickets';
        } else if (role == 'AgencyOwner') {
            url = '/api/my/agents/' + agent.AgentCode + '/tickets';
        } else {
            url = '/api/my/tickets';
        }
        return this.getRequest(url, params).then(function(json) {
            return json
        })
    },

    getAgentPersonalProductionStats(agentcode, options, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        this.cancelQRequest("agentStatsQProduction")
        return this.getRequest('/api/my/stats', options).then(function(json) {
            return (json);
        })

    },

    getAgencyData(agentcode, filters, options, role) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {
            ...options,
            ...filters
        }
        this.cancelQRequest("agentDataQ")
        if (role == 'SuperAdmin' || role == 'Exec') {
            return this.getRequest('/api/private/agents/' + agentcode + "/data", params, 'agentDataQ').then(function(json) {
                return (json);
            })
        } else if (role == 'AgencyOwner') {
            return this.getRequest('/api/my/agents/' + agentcode + "/data", params, 'agentDataQ').then(function(json) {
                return (json);
            })
        } else {
            return this.getRequest('/api/my/data', params).then(function(json) {
                return (json);
            })
        }

    },

    checkNewEmail(agentEmail) {
        return this.postRequest('/api/private/agent_email', { email: agentEmail })
            .then(
                function(json) {
                    return json.Count == 0
                },
                function(err) {
                    if (err.status == 404) {
                        var msg = "Could not pull data."
                    } else {
                        msg = err.message
                    }
                    return (err)
                })
    },

    getAgentPermissions(agentcode) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {}
        this.cancelQRequest("agentPermissionsQ")
        return this.getRequest('/api/private/agents/' + agentcode + "/permissions", params, "agentPermissionsQ").then(function(json) {
            return (json);
        })

    },

    addAgentPermission(agentcode, permission) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {
            'permission': permission,
            'action': 'add'
        }
        this.cancelQRequest("agentPermissionsQ")
        return this.postRequest('/api/private/agents/' + agentcode + "/permissions", params, "agentPermissionsQ").then(function(json) {
            return (json);
        })

    },

    deleteAgentPermission(agentcode, permission) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {
            'permission': permission,
            'action': 'remove'
        }
        this.cancelQRequest("agentPermissionsQ")
        return this.postRequest('/api/private/agents/' + agentcode + "/permissions", params, "agentPermissionsQ").then(function(json) {
            return (json);
        })

    },

    getAgentConfigs(agentcode) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {}
        this.cancelQRequest("agentConfigurationQ")
        return this.getRequest('/api/private/agents/' + agentcode + "/configs", params, "agentConfigurationQ").then(function(json) {
            return (json);
        })

    },

    addAgentConfig(agentcode, config, config_type) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {
            'config': config,
            'config_type': config_type
        }
        this.cancelQRequest("agentConfigurationQ")
        return this.postRequest('/api/private/agents/' + agentcode + "/config", params, "agentConfigurationQ").then(function(json) {
            return (json);
        })

    },

    updateAgentConfig(agentcode, config) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {
            'config': config
        }
        this.cancelQRequest("agentConfigurationQ")
        return this.putRequest('/api/private/agents/' + agentcode + "/config/" + config.ID, params, "agentConfigurationQ").then(function(json) {
            return (json);
        })

    },

    deleteAgentConfig(agentcode, config_id) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        this.cancelQRequest("agentConfigurationQ")
        return this.deleteRequest('/api/private/agents/' + agentcode + "/config/" + config_id).then(function(json) {
            return (json);
        })
    },

    // agent config calendar url
    getAgentConfigsAppointmentCalendar(agentcode, pool) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {
            'reset_pool' : pool
        }
        this.cancelQRequest("appointmentcalendarQ")
        return this.getRequest('/api/private/agents/' + agentcode + "/configs/appointmentcalendarconfig", params, "appointmentcalendarQ").then(function (json) {
            return (json);
        })
    },

    getDownlineCalendarConfig (agentcode, params) {
        if (!agentcode) {
            return new Promise((resolve, reject) => { reject() })
        }
		const cancelQRequestLabel = 'downlineCalendarConfigQ'
        this.cancelQRequest(cancelQRequestLabel)
        return this.getRequest(`/api/private/agents/${agentcode}/configs/downlinecalendarconfig`, params, cancelQRequestLabel)
			.then((json) => json)
    },

    getAgentConfigsBaseshopAppointmentCalendar(agentcode, reset_pool) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var params = {
            'reset_pool' : reset_pool
        }
        this.cancelQRequest("baseshopappointmentcalendarQ")
        return this.getRequest('/api/private/agents/' + agentcode + "/configs/baseshopappointmentcalendar", params, "baseshopappointmentcalendarQ").then(function(json) {
            return (json);
        })
    },

    storeAgentConfigAppointmentCalendar(agentcode, params) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }

        this.cancelQRequest("storeappointmentcalendarQ")
        return this.postRequest('/api/private/agents/' + agentcode + "/configs/storeappointmentcalendar", params, "storeappointmentcalendarQ").then(function(json) {
            return (json);
        })
    },

   addAgentConfigAppointmentCalendar(agentcode, params) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }

        this.cancelQRequest("addappointmentcalendarQ")
        return this.putRequest('/api/private/agents/' + agentcode +  "/configs/addappointmentcalendar", params, "addappointmentcalendarQ").then(function(json) {
            return (json);
        })
    },

    updateAgentConfigAppointmentCalendar(agentcode, params) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }

        this.cancelQRequest("updateappointmentcalendarQ")
        return this.putRequest('/api/private/agents/' + agentcode +  "/configs/updateappointmentcalendar", params, "updateappointmentcalendarQ").then(function(json) {
            return (json);
        })

    },

    deleteAgentConfigAppointmentCalendar(agentcode, params) {
        if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
            return new Promise((resolve, reject) => { reject() });
        }

        this.cancelQRequest("deleteappointmentcalendarQ")
        return this.putRequest('/api/private/agents/' + agentcode +  "/configs/deleteappointmentcalendar", params, "deleteappointmentcalendarQ").then(function(json) {
            return (json);
        })

    },

	storeUserAppointmentCalendar (AppointmentCalendarURL) {
        // if (!AppointmentCalendarURL) {
        //     return new Promise((resolve, reject) => { reject() })
        // }

		const cancelRequestLabel = 'storeUserAppointmentCalendarQ'
        this.cancelQRequest(cancelRequestLabel)

		const params = { AppointmentCalendarURL }
        return this.postRequest('/api/my/appointmentcalendarconfig', params, cancelRequestLabel)
			.then((json) => json)
			.catch((error) => {
				return error
			})
    },

    // getAgentDFLAppointmentUrl(agentcode) {
    //     if (typeof agentcode == 'undefined' || agentcode == null || agentcode == '') {
    //         return new Promise((resolve, reject) => { reject() });
    //     }
    //     var params = {}
    //     this.cancelQRequest("agentConfigurationQ")
    //     return this.getRequest(`/api/private/agents/${agentcode}/dfl_appointment_url`, params, "agentConfigurationQ").then(function(json) {
    //         return (json);
    //     })
    // }

    getDormantStatus() {
        return this.getRequest('/api/private/dormant_status').then(function(json) {
            return (json);
        })
    },
    switchSMSNotificationForWritingNumbers(agentcode, value) {
        return this.postRequest('/api/private/agents/' + agentcode + '/configs/SMSNotificationForWritingNumbers', {
			value,
		})
			.then((json) => json)
			.catch((error) => error)
    },
}
