import { Model } from '@vuex-orm/core'

export default class Agent extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'agent'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/agents',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: [
                'Status',
                'ContractLevel',
                'State',
                "LeadershipLevel",
                "Division",
                "Licensed",
                "PCSParticipant",
                "AvailableInd",
                'SeasonedNewAgentInd',
                'IsOwnerCircleMember',
                'HasSherpaInd',
                'PCSAgentInd',
                'PCSOnHoldInd',
				'IBCCertifiedInd',
                'IULCertifiedInd',
                'AnnuityCertifiedInd',
                'DFLCertifiedInd',
				'IsAdvisoryBoardMember',
				'LegacyAdvisoryBoardMember',
				'PCSProbationInd',
                'Six_Apps',
                'Fifteen_Apps',
                'Twenty_Five_Apps',
				'Full_Time',
				'Part_Time',
				'Builder',
				'Producer',
                'AnnuitySME',
                'DFLSME',
                'Top_Producer',
                'Elite_Producer',
            ],
            availableFilters: null,
            currentAgent: {
                NeedsUpdate: false
            }
        }
    }



    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            AgentsVUEID: this.attr(null),
            AgentCode: this.uid(''),
            OptID: this.attr(''),
            Status: this.attr(''),
            AgentName: this.attr(''),
            LeadershipLevel: this.attr(''),
            ProducerLevel: this.attr(''),
            //Role: this.attr(''), ///refactored to CommissionLevel... let remove this when safe.
            ContractLevel: this.attr(''),
            UplineAgentName: this.attr(''),
            UplineAgentCode: this.attr(''),
            UplineOptID: this.attr(''),
            BaseShopOwnerAgentCode: this.attr(''),
            BaseShopOwnerOptID: this.attr(''),
            BaseshopOwnerAgentName: this.attr(''),
            SFGDirectAgentCode: this.attr(''),
            IsAgencyOwner: this.attr(false),
            IsSFGDirect: this.attr(false),
            HasTotalAgency: this.attr(false),
            StreetAddress: this.attr(''),
            City: this.attr(''),
            County: this.attr(''),
            State: this.attr(''),
            Zip: this.attr(''),
            AgentPhone: this.attr(''),
            BusinessPhone: this.attr(''),
            SummitPhone: this.attr(''),
            CellPhone: this.attr(''),
            AgentEmail: this.attr(''),
            ContractStartDt: this.attr(''),
            LastActivityDate: this.attr(''),
            Upline: this.attr(''),
            Avatar: this.attr(''),
            NeedsUpdate: this.attr(false),
            Division: this.attr(''),
            DoingBusinessAsName: this.attr(''),
            DoingBusinessAsNPN: this.attr(''),
            SFGDirectAgentName: this.attr(''),
            IsFullyLicensed: this.attr(true),
            HasManagerAccess: this.attr(false),
            PCSParticipant: this.attr(false),
            PCSStartDate: this.attr(''),
            PCSAgentInd: this.attr(false),
            PCSProbationInd: this.attr(false),
            PCSOnHoldInd: this.attr(false),
            PCSAgentStartDate: this.attr(''),
            PCSAgentEndDate: this.attr(''),
            PCSAgentEndDate: this.attr(''),
            LastLoginDate: this.attr(''),
            IsOwnerCircleMember: this.attr(false),
            HasSherpaInd: this.attr(false),
            SlingshotBadges: this.attr(''),
            LeadAgentID: this.attr(''),
            AgencyIntegratorID: this.attr(''),
            SeasonedNewAgentInd: this.attr(false),
            CurrentSummitBadge: this.attr(null),
            AgentAccount: this.attr(''),
            IsAdvisoryBoardMember: this.attr(false),
            AdvisoryBoardMemberDetail: this.attr(''),
            HasDFLSMEInDownline: this.attr(''),
			IBCCertifiedInd: this.attr(false),
            IULCertifiedInd: this.attr(false),
            AnnuityCertifiedInd: this.attr(false),
            DFLCertifiedInd : this.attr(false),
            NavigatorAccess : this.attr(false),
        }
    }

    get name_address() {
        return `${this.Applicant} ${this.Address} ${this.City}`
    }
}